<template>
    <div class="account-menu">
        <div class="account-menu__wrapper">
            <router-link to="/account">
                <Row class="account-menu__user">
                    <Column class="account-menu__avatar">
                        <UserAvatar :params="{avatar, size: $viewport.desktop ? 50 : 40}"/>
                    </Column>
                    <Column justify="center">
                        <router-link to="/account"
                                     class="account-menu__title">Мой личный кабинет
                        </router-link>
                        <p class="account-menu__email">{{$user.email}}</p>
                    </Column>
                </Row>
            </router-link>
            <transition name="fade"
                        mode="out-in">
                <TourCompanyMenu v-if="$agency && $my.agencies"
                                 :params="{id: $agency.id,
                          title: $agency.title,
                          logo: $agency.logo,
                          outOfList: true}"/>
            </transition>
            <ul class="account-menu__list">
                <li :key="item.id"
                    class="account-menu__list-item"
                    v-for="item in list">
                    <router-link :to="`${item.href}`"
                                 class="account-menu__list-item-link"
                                 :class="item.href === $route.path ?
                       'account-menu__list-item-active' : ''">
                        {{item.text}}
                    </router-link>
                </li>
            </ul>
            <Divider/>
            <transition name="fade"
                        mode="out-in">
                <Loader v-if="!ready"
                        :params="{style: {width: '100%',height: '200px',margin: '20px 0'}}"/>
                <div v-else
                     class="account-menu__tour-companies">
                    <TourCompanyMenu v-for="item in $my.agencies"
                                     :key="item.id"
                                     :params="{id: item.id,
                           title: item.title,
                           logo: item.logo}"/>
                </div>
            </transition>
            <Divider/>
            <Row class="account-menu__exit-button">
                <div class="account-menu__button"
                     v-on:click="logout">
                    <Icon class="account-menu__icon"
                          viewport="0 0 20 20"
                          xlink="logout"/>
                    <span>Выйти из всех сервисов</span>
                </div>
            </Row>
        </div>
    </div>
</template>

<script>

export default {
  name: 'AccountMenu',
  data() {
    return {
      ready: false,
    };
  },
  methods: {
    logout() {
      this.$store.commit('SET_CLEAR_TOKEN');
      this.$store.commit('SET_LOGOUT_USER');
      window.location.pathname = '/logout';
      // this.$router.push('/logout');
    },
  },
  computed: {
    avatar() {
      return this?.$user?.avatar;
    },
    list() {
      return [{
        href: '/account/favorites',
        text: 'Хочу посетить',
      }, {
        href: (this.$agency && this.$my.agencies) ? '/account/excursions' : '/account/trips',
        text: (this.$agency && this.$my.agencies) ? 'Мои экскурсии' : 'Мои маршруты',
      }, {
        href: '/account/places',
        text: 'Мои объекты',
      }, {
        href: '/account/facts',
        text: 'Интересные факты',
      }, {
        href: '/account/become-a-guide',
        text: 'Мой гид',
      }, {
        href: '/account/agencies',
        text: 'Мои организации',
      }];
    },
  },
  created() {
    if (this.$my.agencies.length === 0) {
      this.$http.get('/api/agencies?user=current&count=100500')
        .then((resolve) => {
          this.$store.commit('SET_MY_AGENCIES', resolve.data.data);
          this.ready = true;
        });
    } else {
      this.ready = true;
    }
  },
};
</script>
